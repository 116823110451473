import React from 'react'
import Navmobile from '../components/Navmobile'
import Navdesktop from '../components/Navdesktop'

function Navbar() {
  return (
    <>
     <Navdesktop/>
     <Navmobile/>
      
    </>
  )
}

export default Navbar